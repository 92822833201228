/** @jsx jsx */
import { Link } from "gatsby";
import { memo, ReactElement } from "react";
import { jsx, BoxProps, Box, Text } from "theme-ui";
import { buttonSx, titleSx } from "../style";

const StartPlayingBlurb = memo(function StartPlayingBlurb(
  props: BoxProps,
): ReactElement {
  return (
    <Box {...props} sx={{ textAlign: "center" }}>
      <Text sx={titleSx}>Start playing</Text>
      <Text mt={2} sx={{ fontSize: 2, color: "gray.5" }}>
        Dapp Queens is the first premier crypto fantasy platform.
        <br />
        Test your investing strategy, compete, and win real money.
      </Text>
      <Link
        to="/play"
        sx={{
          ...buttonSx,
          mt: 4,
          textDecoration: "none !important",
          userSelect: "none",
        }}
        draggable={false}
      >
        Play now!
      </Link>
    </Box>
  );
});
export default StartPlayingBlurb;
