/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx, BoxProps, Flex, Text } from "theme-ui";
import prizeBackgroundImage from "../../images/prize-background.png";

export interface CurrentPoolViewProps extends BoxProps {
  prizePool: number | undefined;
}

const NON_BREAKING_SPACE = "\xa0";

const CurrentPoolView = memo(function CurrentPoolView({
  prizePool,
  ...boxProps
}: CurrentPoolViewProps): ReactElement {
  return (
    <Flex
      {...boxProps}
      pt={4}
      pb={3}
      sx={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        border: "2px solid white",
        borderRadius: "16px",
        textAlign: "center",
        backgroundImage: `url(${prizeBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Text sx={{ fontSize: 3, color: "gray.9", fontWeight: "bold" }}>
        Current Prize Pool
      </Text>
      <Text
        sx={{
          color: "gray.9",
          fontSize: 8,
          fontWeight: "bold",
          WebkitTextStroke: "2px white" as any,
          transition: "opacity ease-out 500ms",
          opacity: prizePool != null ? 1 : 0,
        }}
      >
        {prizePool != null
          ? `${prizePool.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} ETH`
          : NON_BREAKING_SPACE}
      </Text>
    </Flex>
  );
});
export default CurrentPoolView;
