/** @jsx jsx */
import { memo, ReactElement } from "react";
import { useSelector } from "react-redux";
import { jsx, Flex } from "theme-ui";
import Layout from "../components/Layout";
import CurrentPoolView from "../components/home/CurrentPoolView";
import StartPlayingBlurb from "../components/home/StartPlayingBlurb";
import { RootState, getPrizePool } from "../redux/root";

const IndexPage = memo(function IndexPage(): ReactElement {
  const contestInfo = useSelector((state: RootState) => state.contestInfo);

  return (
    <Layout title="Welcome">
      <Flex sx={{ flexDirection: "column", alignItems: "stretch" }}>
        <StartPlayingBlurb mt={5} />
        <CurrentPoolView
          mt={6}
          mb={5}
          prizePool={contestInfo.value && getPrizePool(contestInfo.value)}
        />
      </Flex>
    </Layout>
  );
});
export default IndexPage;
